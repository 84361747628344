<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('project.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/projects/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <button
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="flex mt-5 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto lg:ml-2 lg:mr-2">
            <button
              class="btn btn-outline-secondary w-full sm:w-auto dark:text-white"
              aria-expanded="false"
              :disabled="selected.length == 0"
              :class="selected.length > 0 ? 'dropdown-toggle' : ''"
            >
              {{ i18n('common.table.actions') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <Trash2Icon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.delete') }}
                </a>
                <a
                  href="javascript:;"
                  @click="doEnableAllSelected()"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.activate') }}
                </a>
                <!-- data-toggle="modal"
                  data-target="#disable-modal-preview" -->
                <a
                  href="javascript:;"
                  @click="doDisableAllSelected()"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <LockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.disable') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#000"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <!-- scrollbar-hidden -->
          <table
            class="table table-report sm:mt-2"
            id="tablePrint"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="ignore-5">#</th>
                <!-- border-b-2 dark:border-dark-5 -->
                <th class="whitespace-nowrap text-center" id="ignore-1">
                  <!-- border-b-2 dark:border-dark-5 -->
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-2' : ''"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40" id="ignore-6">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td id="ignore-3">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td>
                <!-- <td class="text-center flex items-center justify-center">
                  <app-list-item-image :value="row.image"></app-list-item-image>
                </td> -->
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ row.id }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'name') }}
                  </div>
                </td>
                <td class="text-center w-72">
                  <div class="font-medium whitespace-nowrap w-72 truncate">
                    {{ presenterLocalization(row, 'description') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row.address, 'name') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'projectType') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-4">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <router-link
                      class="
                        text-edit
                        dark:text-theme-30
                        flex
                        items-center
                        gap-2
                        whitespace-nowrap
                      "
                      :to="`/projects/${row.id}/edit`"
                    >
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </router-link>
                    <router-link
                      class="
                        text-view
                        dark:text-theme-30
                        flex
                        items-center
                        gap-2
                        whitespace-nowrap
                      "
                      :to="`/projects/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      @click="selectedId = row.id"
                      class="
                        text-theme-24
                        flex
                        items-center
                        gap-1
                        whitespace-nowrap
                      "
                    >
                      <Trash2Icon class="w-4 h-4" />
                      {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.project')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination?.limit"
          :rowsNumber="pagination?.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="project" @delete="doDestroy(selectedId)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export default {
  components: {
    DeleteModal
  },
  setup() {
    const tableRef = ref()
    const selected = ref([])
    const selectAll = ref(false)
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      selected,
      selectAll
    }
  },
  data() {
    return {
      selectedId: null,
      filterInput: [],
      columns: [
      {
          name: 'id',
          field: 'id',
          label: 'project.fields.id',
          align: 'center'
        },
        {
          name: 'name',
          field: 'name',
          label: 'project.fields.name',
          align: 'center'
        },
        {
          name: 'description',
          field: 'description',
          label: 'project.fields.description',
          align: 'center'
        },
        {
          name: 'address',
          field: 'address',
          label: 'project.fields.address',
          align: 'center'
        },
        {
          name: 'projectType',
          field: 'projectType',
          label: 'project.fields.projectType',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'project.fields.createdAt',
          align: 'center'
        },
        // {
        //   name: 'status',
        //   field: 'status',
        //   label: 'project.fields.status',
        //   align: 'center'
        // },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'project/list/rows',
      pagination: 'project/list/pagination',
      loading: 'project/list/loading'
    }),
    currentPage() {
      // return 1
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        // return index
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    projectTypeOptions() {
      return [
        {
          label: this.i18n('common.underConstruction'),
          value: 'underConstruction'
        },
        { label: this.i18n('common.launched'), value: 'launched' },
        { label: this.i18n('common.finished'), value: 'finished' }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.name'),
          value: 'name',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.projectType'),
          value: 'projectType',
          type: 'list',
          options: this.projectTypeOptions
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'project/list/doFetch',
      doFetchNextPage: 'project/list/doFetchNextPage',
      doFetchPreviousPage: 'project/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'project/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'project/list/doChangePaginationPageSize',
      doDestroy: 'project/list/doDestroy'
    }),
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage(orderBy) {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: orderBy || 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'userTable', 'users')
    },
    onDownloadPdf() {
      $h.downloadPdf('#tablePrint', 'users', [1, 7])
    },
    onPrint() {
      print({
        printable: 'tablePrint',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
